/* eslint-disable import/no-unresolved */
/* eslint-disable consistent-return */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable react/jsx-props-no-spreading */
import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { renderToString } from 'react-dom/server'
import parse, { domToReact } from 'html-react-parser'
import styled from 'styled-components'
import { ButtonDefault } from 'components/Elements/Elements'
import CustomLink from './CustomLink'

const Content = styled.div`
  /* color: inherit;
  font-weight: ${props => props.theme.font.weight.m};
  letter-spacing: 0.5;

  .text-small {
    font-size: ${props => props.theme.font.size.sm};
  }

  .text-normal {
    font-size: ${props => props.theme.font.size.m};
  }

  & strong, & b {
    font-weight: ${props => props.theme.font.weight.l};
  }

  & h1, & h2, & h3, & h4, & h5 {
    color: ${props => props.theme.color.text.secondary};
    text-align: inherit;
    font-weight: ${props => props.theme.font.weight.s};

    & strong, & b {
      font-weight: ${props => props.theme.font.weight.l};
    }
  }

  & h1 {
    margin-bottom: 20px;

    @media (min-width: 992px) {
      font-size: ${props => props.theme.font.size.xxl};
      line-height: ${props => props.theme.font.size.xxl};
    }

    @media (max-width: 991px) {
      font-size: ${props => props.theme.font.size.xl};
      line-height: ${props => props.theme.font.size.xl};
    }
  }

  & h2, & h3, & h4 {
    @media (min-width: 992px) {
      font-size: ${props => props.theme.font.size.xm};
      line-height: ${props => props.theme.font.size.xm};
    }

    @media (max-width: 991px) {
      font-size: ${props => props.theme.font.size.m};
      line-height: ${props => props.theme.font.size.m};
    }
  }

  & img {
    border-radius: 10px;
  }

  & blockquote {
    padding: 45px 65px 35px 65px;
    color: ${props => props.theme.color.text.main};
    font-weight: ${props => props.theme.font.weight.l};
    font-size: ${props => props.theme.font.size.xl};
    line-height: ${props => props.theme.font.size.xl};
    position: relative;

    &:before {
      content: '“';
      color: ${props => props.theme.color.text.secondary};
      font-size: 100px;
      position: absolute;
      top: 30px;
      left: 15px;
    }
  }

  & ul {
    
    & li {
      
    }
  }

  & a {
    color: ${props => props.theme.color.text.secondary};
    text-decoration: underline;
  }
*/
  & img {
    width: 100%;
    height: auto;
    object-fit: cover;
    max-width: 100%;
  }

  /*
    WP WYSIWYG
  */
 .aligncenter {
    display: block;
    margin: 0 auto;
    text-align: center;
  }

  .alignright {
    float: right;
    text-align: right;
  }

  .wp-caption {
    max-width: 100%;
  }

  .wp-caption-text {
    font-size: 12px;
    font-weight: 700;
    text-align: center;
  }
`

const capitalize = (s) => {
  if (typeof s !== 'string') return ''
  return s.charAt(0).toUpperCase() + s.slice(1)
}

const convertCSSKey = key => {
  const keySplit = key.split('-')
  let newKey = ''

  keySplit.forEach((value, index) => {
    let newValue = value

    if (index > 0) {
      newValue = capitalize(value)
    }

    newKey += newValue
  })

  return newKey
}

const convertCSS = style => {
  const result = {}

  if (!style) {
    return result
  }

  const attributes = style.split(';');

  attributes.forEach(attribute => {
    const entry = attribute.split(':')

    // eslint-disable-next-line prefer-destructuring
    result[convertCSSKey(entry[0])] = entry[1]
  })

  return result
}

const findAndReplace = (content, settings) => {
  return parse(content, {
    replace: (domNode) => {
      if (domNode.name === 'a' && domNode.attribs.class === 'button-normal') {
        const attributes = domNode.attribs

        if (
          attributes.src &&
          attributes.src.indexOf(settings.sourceUrl) === -1
        ) {
          attributes.src = `${settings.sourceUrl}${attributes.src}`
        }

        if (attributes.href && (
          attributes.href.indexOf('www.') !== -1 ||
          attributes.href.indexOf('http:') !== -1 ||
          attributes.href.indexOf('https:') !== -1
        )) {
          attributes.target = '_blank'

          return (
            <ButtonDefault isCustom>
              <a href={attributes.href} target={attributes.target}>
                {domToReact(domNode.children, {})}
              </a>
            </ButtonDefault>
          )
        }

        return (
          <ButtonDefault to={domNode.attribs.href} target={domNode.attribs && domNode.attribs.target}>
            {domToReact(domNode.children, {})}
          </ButtonDefault>
        )
      }

      if (domNode.children && domNode.children.length > 0) {
        const html = renderToString(domToReact(domNode.children, {}))
        const htmlFindAndReplace = findAndReplace(html, settings)

        const attributes = domNode.attribs

        if (
          attributes.src &&
          attributes.src.indexOf(settings.sourceUrl) === -1
        ) {
          attributes.src = `${settings.sourceUrl}${attributes.src}`
        }

        if (attributes.href) {
          if (
            attributes.href.indexOf('www.') !== -1 ||
            attributes.href.indexOf('http:') !== -1 ||
            attributes.href.indexOf('https:') !== -1
          ) {
            attributes.target = '_blank'
          } else if (attributes.href.indexOf('/wp-content/') !== -1 && attributes.href.indexOf(settings.sourceUrl) === -1) {
            attributes.href = `${settings.sourceUrl}${attributes.href}`
          }
        }

        attributes.className = attributes.class

        delete attributes.class

        if (attributes.style) {
          attributes.style = convertCSS(attributes.style)
        }

        const Comp = domNode.name

        return (
          <Comp
            src={attributes.src}
            className={attributes.className}
            style={attributes.style}
            id={attributes.id}
            href={attributes.href}
            target={attributes.target}
          >
            {htmlFindAndReplace}
          </Comp>
        )
      }

      if (domNode.name === 'img') {
        const attributes = domNode.attribs

        if (domNode.attribs.src.indexOf('/wp-content/') !== -1 && domNode.attribs.src.indexOf(settings.sourceUrl) === -1) {
          attributes.src = `${settings.sourceUrl}${domNode.attribs.src}`
        }

        attributes.className = attributes.class

        delete attributes.class

        if (attributes.style) {
          attributes.style = convertCSS(attributes.style)
        }

        return (
          <img
            src={attributes.src}
            className={attributes.className}
            style={attributes.style}
            width={attributes.width}
            height={attributes.height}
            alt={attributes.alt}
          />
        )
      }

      if (domNode.name === 'a') {
        const attributes = domNode.attribs

        if (attributes.style) {
          delete attributes.style
        }

        if (
          attributes.href.indexOf('www.') !== -1 ||
          attributes.href.indexOf('http:') !== -1 ||
          attributes.href.indexOf('https:') !== -1
        ) {
          return (
            <a {...attributes} target="_blank">
              {domToReact(domNode.children, {})}
            </a>
          )
        }

        return (
          <CustomLink to={attributes.href} {...attributes}>
            {domToReact(domNode.children, {})}
          </CustomLink>
        )
      }
    },
  })
}

const ParseContent = ({ content, className, isLightHs }) => {
  const {
    site
  } = useStaticQuery(graphql`
    {
      site {
        siteMetadata {
          siteWpUrl
        }
      }
    }
  `)

  let sourceUrl = ''

  if (site && site.siteMetadata && site.siteMetadata.siteWpUrl) {
    sourceUrl = site.siteMetadata.siteWpUrl
  }

  if (!content) {
    return ''
  }

  return (
    <Content className={className} isLightHs={isLightHs}>
      {findAndReplace(content, { sourceUrl })}
    </Content>
  )
}

export default ParseContent