import React from 'react'
import { graphql } from 'gatsby'

// Components
import Layout from 'components/Layout'
import SEO from 'components/SEO'
import CustomLink from 'components/CustomLink'
import Specialist from 'components/Specialist'
import BlogErvaringHeader from 'components/PreviewContainer/BlogErvaringHeader'

// CSS
import 'styles/Ervaring.scss';

// Third Party
import parse from 'html-react-parser'
import ParseContent from '../components/ParseContent'

export const ErvaringTemplate = (
  {
    title,
    ervaringImage,
    location,
    quote,
    h1Tag,
    story,
    specialist
  }) => {

  return (
    <section className="section ervaring-detail">
      <h1 className="d-none">{h1Tag}</h1>
      <div className="ervaring-detail-thin-line d-lg-block d-none color-background-contrast" />
      <BlogErvaringHeader className="pb-5 pb-lg-0" image={ervaringImage} title={title} location={location} quote={quote} />
      <div className="ervaring-detail-thin-line d-lg-block d-none color-background-secondary" />

      <div className="container ervaring-detail-container">
        <div className="row">
          <div className="col-12 pt-3 pb-2 back-to-blog">
            <CustomLink to="/ervaringen" className="font-weight-xl font-size-sm">&gt; Terug naar ervaringoverzicht</CustomLink>
          </div>
          <div className="col-12 col-lg-8">
            <div className="pt-2 pb-5 ervaring-detail-content">
              <ParseContent content={story} />
            </div>
          </div>
          <div className="col-12 col-lg-4 pt-2 d-flex justify-content-center justify-content-lg-end">
            <Specialist specialist={specialist} />
          </div>
        </div>

        <div className="empty-space-100" />
      </div>
    </section>
  )
}

const Ervaring = ({ data }) => {

  const { wordpressWpErvaring: ervaring } = data

  return (
    <Layout>
      <SEO yoast={{ meta: ervaring.yoast_meta }} />
      <ErvaringTemplate
        title={ervaring.acf.preview.title}
        location={ervaring.acf.preview.location}
        ervaringImage={ervaring.acf.preview.image.localFile.childImageSharp.fluid}
        story={ervaring.acf.content.story}
        h1Tag={ervaring.acf.content.h1_tag}
        quote={ervaring.acf.preview.quote}
        specialist={ervaring.acf.specialist}
      />
    </Layout>
  )
}

export default Ervaring

export const pageQuery = graphql`
  fragment ErvaringPostFields on wordpress__wp_ervaring {
    id
    slug
  }
  query DetailErvaringQuery($id: String!) {
    wordpressWpErvaring(id: {eq: $id}) {
      title
      slug
      acf {
        preview {
          location
          quote
          small_introduction
          title
          image {
            localFile {
              childImageSharp {
                fluid(quality: 100, maxWidth: 1920) {
                  ...GatsbyImageSharpFluid_withWebp_noBase64
                }
              }
            }
          }
        }
        content {
          story
          h1_tag
        }
        specialist {
          title
          slug
          acf {
            number
            specialist_text
            image {
              localFile {
                childImageSharp {
                  fluid(quality: 100, maxWidth: 250) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
          }
        }
      }
      yoast_meta {
        content
        name
        property
      }
    }
  }
`
